
import { IonGrid, IonApp, IonCol, IonRow, alertController, IonCard, IonCardContent } from "@ionic/vue";

import { mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import Header from "../items/Header.vue";
import MainMenuDesktop from "../items/MainMenuDesktop.vue";
import RowHeaderPeticiones from "../items/RowHeaderPeticiones.vue";
import Footer from "../items/Footer.vue";
import ButtonSolicitudPeticion from "../items/elements/ButtonSolicitudPeticion.vue";
import functions from "../src/functions";
import { TokenService } from "@/services/token.service";
import { useQuery, useResult } from "@vue/apollo-composable";
import { clienteQuery } from "@/graphql/queries/clienteQuery";
import { reactive } from "vue";
import { AuthService } from "@/services/auth.service";

export default {
  name: "Peticion",
  components: {
    IonGrid,
    IonCol,
    IonRow,
    MainMenuDesktop,
    RowHeaderPeticiones,
    ButtonSolicitudPeticion,
    IonCard,
    IonCardContent,
  },
  setup() {
    const router = useRouter();
    const id: any = TokenService.getId();
    const token: string = TokenService.getToken();
    const variables = reactive({ id: parseInt(id), token: token });

    const { result, loading, error, onResult, onError } = useQuery(clienteQuery, variables, {
      fetchPolicy: "cache-only",
    });

    onError(() => {
        console.log(error.value);
        AuthService.signOut();
window.location.href="/"; 
      })

    const contrato = useResult(result, null, (data) => data.cliente.contratos);

    require("../css/index.css");
    return {
      router,
      loading,
      error,
      contrato,
      functions,
    };
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      cancelacionAnti: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticating", "authenticationError", "authenticationErrorCode"]),
  },
  methods: {
    ...mapActions("auth", ["signIn"]),
    async handleLogin() {
      this.signIn(this.form)
        .then(() => {
          this.form.username = "";
          this.form.password = "";
        })
        .catch(this.alerta());
    },
  },
  watch: {
    contrato: async function () { 
      this.cancelacionAnti = await functions.cancelacionAnticipada(this.contrato.id);
      
    },
  },
};
